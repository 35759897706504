<template>
  <div class="bg-white p-4">
    <b-row no-gutters>
      <b-col cols="3" class="mr-2">
        <rm-input
          v-model="filterOptions.search"
          outlined
          placeholder="Search..."
        ></rm-input>
      </b-col>
      <b-col cols="3" class="d-flex">
        <rm-base-button
          class="mr-3"
          text="Search"
          @click="loadData"
        ></rm-base-button>
        <rm-base-button
          bgColor="danger"
          text="Clear Search"
          @click="clearFilter"
        ></rm-base-button>
      </b-col>
      <!-- <b-col cols="2">
        <rm-base-button
          @click="showFilter = !showFilter"
          class="ml-2"
          :text="`${showFilter ? 'Hide' : 'Show'} Filters`"
          bgColor="primary--light"
          textColor="primary"
          ><rm-base-icon slot="post-icon" class="ml-3" name="filter-icon"
        /></rm-base-button>
      </b-col> -->
      <!-- <b-col></b-col> -->
      <!-- <b-col cols="2">
        <div class="d-flex w-100">
          <rm-base-button
            @click="
              exportData({
                items,
                config: {},
                title: 'Realtors Report',
              })
            "
            class="ml-auto"
            text="Export"
            bgColor="primary"
            textColor="white"
            ><rm-base-icon slot="post-icon" class="ml-2" name="export-icon"
          /></rm-base-button>
        </div>
      </b-col> -->
    </b-row>

    <!-- <b-row v-if="showFilter">
      <b-col cols="2" class="bordered ml-3">
        <rm-drop-down outlined label="Level" :options="levelOptions" />
      </b-col>
      <b-col cols="2" class="ml-2">
        <rm-drop-down outlined label="Gender" :options="genderOptions" />
      </b-col>
      <b-col cols="2" class="ml-2">
        <rm-drop-down outlined label="State" :options="stateOptions" />
      </b-col>
      <b-col cols="2" class="ml-2">
        <rm-drop-down outlined :options="filterOptions" />
      </b-col>
    </b-row> -->

    <!-- <div class="d-flex mb-5" v-if="showFilter">
      <rm-base-button
        class="ml-3"
        text="Filter"
        bgColor="tertiary"
        textColor="white"
      ></rm-base-button>
      <rm-base-button
        class="ml-3"
        text="Clear Filter"
        bgColor="tertiary--light"
        textColor="tertiary"
      ></rm-base-button>
    </div> -->

    <div v-if="showRealtorLevelSelect" class="mb-5">
      <h5>Change realtor level</h5>
      <div class="d-flex align-items-center mt-4">
        <b-form-select
          class="level-select"
          v-model="selectedLevel"
          :options="realtorLevels"
        ></b-form-select>
        <span class="ml-4">
          <b-button
            @click="changeLevel"
            variant="primary"
          >Apply</b-button>
        </span>
      </div>
    </div>

    <b-table
      ref="realtorsTable"
      thead-class="bg-gray-400 text-uppercase text-sm-2"
      tbody-class="text-sm"
      :selectable="true"
      hover
      :busy="isLoading"
      :items="realtors"
      :fields="fields"
      @row-selected="onRowSelected"
    >
      <template #table-busy>
        <div class="text-center text-gray my-5">
          <b-spinner class="align-middle"></b-spinner> <br />
          <strong>Loading Data...</strong>
        </div>
      </template>
      <template #head(check)="">
        <input
          type="checkbox"
          :checked="selectAll && items.length == selected.length"
          @click="selectAll = !selectAll"
        />
      </template>
      <template v-slot:cell(check)="{ rowSelected, index }">
        <div class="d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            :checked="rowSelected"
            @click="handleRowSelect(rowSelected, index)"
          />
        </div>
      </template>
      <template #cell(realtor_name)="{ item }">
        <div
          class="text-primary pointer"
          @click="globalRouter('clients.profile', { id: item.id })"
        >
          {{ `${item.realtor_name}` }}
        </div>
      </template>
      <template #cell(status)="{ item }">
        <div
          class="text-center px-3 py-1 rounded"
          :class="item.status == 'active' ? 'inactive' : 'active'"
        >
          <small>{{ item.status }}</small>
        </div>
      </template>
      <template #cell(realtor_level)="{ item }">
        <div class="text-center px-3 py-1 rounded level">
          <small>{{ item.realtor_level }}</small>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="paging.totalRows"
      v-model="paging.currentPage"
      :total-rows="paging.totalRows"
      :per-page="paging.perPage"
      aria-controls="my-table"
      @change="changePage"
    />
  </div>
</template>

<script>
import formatDate from "@/mixins/formatDate.js";
import exportData from "@/mixins/exportData.js";

export default {
  data() {
    return {
      isLoading: false,
      realtors: [],
      selected: [],
      realtorLevels: [],
      paging: {
        currentPage: 1,
        totalRows: null,
        perPage: null,
      },
      filterOptions: {
        search: null,
        marital_status: null,
        gender: null,
      },
      selectAll: false,
      selectedLevel: null,
      showRealtorLevelSelect: false,
      fields: [
        {
          key: "check",
          label: "",
          class: "text-center",
        },
        {
          key: "realtor_name",
          sortable: true,
          label: "Realtor Name",
          class: "text-center",
        },
        {
          key: "referrer",
          label: "Referrer",
          class: "text-center",
        },
        {
          key: "email",
          label: "Email",
          class: "text-center",
        },
        {
          key: "phone_number",
          label: "Phone Number",
          class: "text-center",
        },
        {
          key: "realtor_level",
          label: "Level",
          class: "text-center",
        },
        {
          key: "wallet_balance",
          label: "Wallet Balance",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
        },
        {
          key: "date_registered",
          label: "Reg Date",
        },
      ],
    };
  },
  mixins: [formatDate, exportData],
  methods: {
    onRowSelected(items) {
      this.selected = items;
      this.toggleLevelSelect()
    },
    selectAllRows() {
      this.$refs.realtorsTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.realtorsTable.clearSelected();
    },
    selectRow(index) {
      this.$refs.realtorsTable.selectRow(index);
    },
    unSelectRow(index) {
      this.$refs.realtorsTable.unselectRow(index);
    },
    handleRowSelect(isRowSelected, index) {
      if (!isRowSelected) {
        this.selectRow(index);
      } else {
        this.unSelectRow(index);
      }
    },
    changePage(pageNo) {
      this.paging.currentPage = pageNo;
      this.loadData();
    },
    clearFilter() {
      this.filterOptions = {
        search: null,
        marital_status: null,
        gender: null,
      };

      this.loadData();
    },
    async loadData() {
      this.isLoading = true;
      try {
        const params = {
          ...this.filterOptions,
          page: this.paging.currentPage,
        };
        await this.fetchRealtors(params);
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchRealtors(params) {
      const { paging, rows } = await this.$store.dispatch(
        "realtors/fetchRealtors",
        params
      );
      this.realtors = rows.map((item) => {
        const { subscriptions, wallet, realtor_level, upline } = item;
        return {
          id: item.id,
          realtor_name: `${item.first_name} ${item.last_name}`,
          referrer: `${upline.first_name} ${upline.last_name}`,
          email: item.email,
          phone_number: item.phone_number,
          realtor_level: realtor_level.name,
          level: realtor_level.level,
          wallet_balance: `₦${this.$formatCurrency(wallet?.balance / 100)}`,
          status: subscriptions && subscriptions.length > 0
            ? 'active'
            : 'inactive',
          date_registered: this.formatDate(item.created_at),
        }
      });

      this.paging.currentPage = paging.currentPageNo;
      this.paging.totalRows = paging.total;
      this.paging.perPage = paging.perPage;
    },
    toggleLevelSelect() {
      if (this.selected.length !== 1) {
        this.showRealtorLevelSelect = false;
        this.selectedLevel = null;
        return;
      }
      this.showRealtorLevelSelect = true;
      this.selectedLevel = this.selected[0].level;
    },
    async changeLevel() {
      try {
        this.isBusy = true;
        const payload = {
          realtor_id: this.selected[0].id,
          level: this.selectedLevel
        }
        const message = await this.$store.dispatch(
          "realtors/updateRealtorLevel",
          payload
        );
        this.$store.dispatch('toast', {
          variant: 'success',
          message,
        });
      } catch (error) {
        this.isBusy = false;
      } finally {
        this.isBusy = false;
        await this.fetchRealtors();
      }
    }
  },
  watch: {
    selectAll(v) {
      if (!v) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
  },
  async created() {
    try {
      await this.loadData()
      const data = await this.$store.dispatch(
        "realtors/fetchRealtorLevel"
      );
      this.realtorLevels = data.map((item) =>  {
        return { value: item.level, text: item.name }
      })
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style lang="scss" scoped>
.active {
  background-color: #fff5e9;
  color: #ec5800;
}
.inactive {
  background-color: #e8fbf4;
  color: #1d9067;
}
.flex {
  display: flex;
}
.level {
  background: rgba(205, 127, 50, 0.1);
  border-radius: 4px;
  color: #cd7f32;
}
.level-select {
  max-width: 200px;
  color: black;
}
</style>
