<template>
  <div>
    <!-- <b-row class="mt-2">
      <b-col cols="2">
        <rm-drop-down outlined :options="metricOptions" />
      </b-col>
      <b-col cols="10" />
      <b-col cols="3" v-for="(metric, ix) in realtorMetrics" :key="ix">
        <rm-metric-card :metric="metric" />
      </b-col>
    </b-row> -->
    <!-- <div class="d-flex">
      <rm-realtor-type-chart class="mr-5" />
      <rm-top-realtors-table />
      <rm-country-chart class="mx-4" />
    </div> -->
    <b-row class="mt-5">
      <b-col cols="12">
        <rm-realtors-table />
      </b-col>
      <b-col cols="4"> </b-col>
      <b-col cols="4"> </b-col>
    </b-row>
  </div>
</template>

<script>
// import RmRealtorTypeChart from "./components/RmRealtorTypeChart.vue";
// import RmTopRealtorsTable from "./components/RmTopRealtorsTable.vue";
// import RmCountryChart from "./components/RmCountryChart.vue";
// import RmMetricCard from "./components/RmMetricCard.vue";
import RmRealtorsTable from "./components/RmRealtorsTable.vue";

export default {
  components: {
    // RmMetricCard,
    // RmRealtorTypeChart,
    // RmTopRealtorsTable,
    RmRealtorsTable,
  },
  data: () => ({
    metricOptions: ["All Time"],
    realtorMetrics: [
      {
        name: "Total Realtors",
        amount: "25K",
        growth: +100,
        icon: "total-clients",
      },
      {
        name: "Active Realtors",
        amount: "250",
        growth: +15,
        icon: "active-clients",
      },
      {
        name: "Inactive Realtors",
        amount: "1000",
        growth: +100,
        icon: "inactive-clients",
      },
      {
        name: "Prospective Realtors",
        amount: "N5.2M",
        growth: -22.5,
        icon: "prospective-clients",
      },
    ],
  }),
};
</script>
